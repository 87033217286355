<template>
  <div id="historyAccording">
    <ul class="accordion" role="tablist" multiselectable="true">
      <li
        v-for="(year, index) in yearList"
        :key="index"
      >
        <div class="panel-title">
          <a :href="'#panel-' + year.id" aria-expanded="false" :aria-controls="'panel-' + year.id" :id="'control-panel-' + year.id" aria-hidden="true">
            <span class="year">{{year}}</span>
            <span class="icon">+</span>
          </a>
        </div>
        <div class="panel-content" :id="'panel-' + year.id" :aria-labelledby="'control-panel-' + year.id" role="tabpanel">
          <ul
            v-for="(history, idx) in dict[year]['list']"
            :key="idx"
          >
            <li class="date">{{history.historyDate}}</li>
            <li class="subject">
              {{ $t("views.historyInfo", { 'var': $i18n.locale === 'ko' ? $t(history.info) : $t(history.infoEnglish)}) }}
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { drawAccor } from '@/assets/js/util.js'

export default {
  props: [
    'dict'
  ],
  data() {
    return {
      yearList: []
    }
  },
  mounted: function () {
    this.$nextTick(() => {
      drawAccor()
      this.yearList = Object.keys(this.dict).reverse()
    })
  }
}
</script>
