<template>
  <!-- container -->
    <div id="container">

      <!-- 본문 -->
      <div id="contents">
        <div class="acryl">
        <!-- 서브 배너 -->
        <section class="subTopArea inner-fixed">
          <div v-html="$t('views.acryl1')"></div>
          <!-- 2023.01.04 회사소개서 숨김처리 -->
          <!-- <div class="btn-cen">
            <a href="/Introduction to Acryl Inc(KOR).pdf" target="_blank" :class="`${$_gGet_Locale()} button button-down`"><div v-html="$t('views.acryl2')"></div></a>
            <a href="/Introduction to Acryl Inc(ENG).pdf" target="_blank" :class="`${$_gGet_Locale()} button button-down`"><div v-html="$t('views.acryl3')"></div></a>
          </div>            
          <div class="btn-cen btn-adobe m-none">
            <a @click="openTo('http://get.adobe.com/kr/reader/?promoid=KSWLH')" v-html="$t('views.acryl4')"></a>
            <p class="info">{{ $t('views.acryl5') }}</p>
          </div> -->
        </section>
        <!-- //서브 배너 -->

        <section class="acrylWrap">
          <section class="acrylAbout inner-fixed">
            <div v-html="$t('views.acryl6')"></div>
          </section>
        </section>

        <section class="acrlyHistory inner-fixed">
          <div class="areaBox">
            <h3>{{ $t('views.acryl7') }}</h3>
            <HistoryAccording v-if="Object.keys(historyDict).length > 0" :dict="historyDict" />
          </div>
        </section>

        </div>
      </div>
      <!-- 본문 -->
    </div>
  <!-- //container -->
</template>

<script>
import HistoryAccording from '@/components/According/HistoryAccording.vue'
import { openTo, scrollTo } from '@/assets/js/util.js'
import instance from '@/assets/js/api.js'

export default {
  name: 'acryl',
  components: {
    HistoryAccording
  },
  data() {
    return {
      historyDict: {}
    }
  },
  methods: {
    getHistoryList () {
      const url = '/history/list'
      const method = 'get'

      let data = instance({
        url,
        method,
      })
      data.then(responseData => {
        const {
          result,
          response
        } = responseData
        if (result) {
          const {
            dict
          } = response

          this.historyDict = dict
        }
      })
      .catch(error => {
      })
    },
    openTo (path) { openTo(path) },
  },
  created: function () {
    this.getHistoryList()
  },
  mounted: function () {
    this.$nextTick(() => {
      if('scrollTo' in this.$route.params) {
        scrollTo(this.$route.params.scrollTo)
      }
    })
  },
}
</script>

<style>

</style>
